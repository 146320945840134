var $ = require('jquery')
var tri = require('tripartite')
var _ = require('underscore')

var publicConfig = {
	key: 'AIzaSyDtt1FHiGxUfqHzY5PhP1cwaiXGSH9vC0o',
	stagger_time:       1000, // for elevationPath 
	encode_polylines:   false,
	secure:             true
};


var normal = {
	center: {lat: 44.023285307360915, lng: -93.06690273284912},
	zoom: 8 
}

var downtownRochester = {
	lat: 44.02368,
	lng: -92.46417
}

var map

var openWindows = []
var allMarkers = []

var mapsloaded = function() {
	if($('#map-section').length > 0) {
		map = new google.maps.Map(document.getElementById('map-section'), normal);
		window.drawMarkers = function(type) {
			_.each(allMarkers, function(marker) {
				marker.setMap(null)
			})
			allMarkers = []
			
			var $parishes = $('.the-parishes .parish')
			var parishCount = $parishes.length	

			function centerMap() {
				if(parishCount == 0) {
					/*
					var latMin 
					var latMax 
					var lngMin 
					var lngMax 

					_.each(allMarkers, function(marker) {
						if(!latMin) {
							latMin = marker.position.lat
						}
						else {
							if(marker.position.lat < latMin) {
								latMin = marker.position.lat
							}
						}
						if(!latMax) {
							latMax = marker.position.lat
						}
						else {
							if(marker.position.lat > latMax) {
								latMax = marker.position.lat
							}
						}

						if(!lngMin) {
							lngMin = marker.position.lng
						}
						else {
							if(marker.position.lng < lngMin) {
								lngMin = marker.position.lng
							}
						}
						if(!lngMax) {
							lngMax = marker.position.lng
						}
						else {
							if(marker.position.lng > lngMax) {
								lngMax = marker.position.lng
							}
						}
					})
*/
					var bounds = new google.maps.LatLngBounds()
					_.each(allMarkers, function(marker) {
						bounds.extend(new google.maps.LatLng(marker.position.lat(), marker.position.lng()))
					})
					map.fitBounds(bounds)
				}
			}

			$parishes.each(function(data) {
				var property = {

				}
				var $self = $(this)
				var address = $(this).find('.address').text().trim().replace(/\s\s+/, ' ')
				var formattedAddress = $(this).find('.address').html()
				var name = $self.find('.name').text() 				
				var createMarker = function(lat, lng) {
					var marker = new google.maps.Marker({
						map: map,
						position: {
							lat: lat, 
							lng: lng
						},
						title: name
					});
					allMarkers.push(marker)
					marker.addListener('click', function() {
						_.each(openWindows, function(win) {
							win.close()
						})

						var bgImage = $self.find('.img').css('background-image')
						bgImage = bgImage.replace('url("', '').replace('")', '')
						var infowindow = new google.maps.InfoWindow({
							content: '<a target="_blank" href="' + $self.find('a').attr('href') + '" class="map-info-window"><span class="title">' + name + '</span><br/>' + formattedAddress + '<img class="primary" src="'+ bgImage + '" /></a>'
						});
						infowindow.open(map, marker);
						openWindows.push(infowindow);
					});
					parishCount--
				}
				if(property.latitude && property.longitude) {
					createMarker(parseFloat(property.latitude), parseFloat(property.longitude))
					centerMap()
				}
				else {
					$.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURI(address) + '&key=' + publicConfig.key, function(data) {
						try {
							createMarker(data.results[0].geometry.location.lat, data.results[0].geometry.location.lng)
						}
						catch(e) {
							parishCount--
							if(console.error) {
								console.error(e)
							}
						}
						centerMap()
					})
				}
			
				
			})
		}
		drawMarkers()
	}
}



if(typeof google == 'undefined') {
	$.getScript('https://maps.googleapis.com/maps/api/js?key=' + publicConfig.key, mapsloaded)
}