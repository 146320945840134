var $ = window.jQuery
// calendar stuff
$($('.year-link').get(0)).addClass('selected');

$('.year-link').click(function(evt) {
	evt.preventDefault();
	var pos = $('.year-link').index(evt.target);
	$('.year-link').removeClass('selected');
	$(evt.target).addClass('selected');
	$($('.month-box').hide().get(pos)).show();
});

$('.month-short-name').click(function(evt) {
	evt.preventDefault();
	var id = evt.target.id;
	
	$('.event-list .event').hide();
	$('.event-list .event.' + id).show();
	
	$('.month-short-name.selected').removeClass('selected');
	$(evt.target).addClass('selected');
});

var now = new Date();
var m = now.getMonth() + '';
if(m.length < 2) {
	m = "0" + m;
}
var curMonth = 'm' + (now.getYear() + 1900) + m;

$('#' + curMonth).click();
