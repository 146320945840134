var PushButtonBar = require('./push-button-bar')
var $ = require('jquery')
var _ = require('underscore')

$('.parishes-search').on('submit', function(evt) {
	evt.preventDefault()
	window.location = '/directories/parishes.html?searchTerm=' + $(this).find('input').val()
})

$('.schools-search').on('submit', function(evt) {
	evt.preventDefault()
	window.location = '/directories/schools.html?searchTerm=' + $(this).find('input').val()
})

$('.cemetery-search').on('submit', function(evt) {
	evt.preventDefault()
	window.location = '/directories/cemeteries.html?searchTerm=' + $(this).find('input').val()
})


if($('.parishes-page, .schools-page, .cemeteries-page').length > 0) {
	var sortBy = new PushButtonBar({
		el: $('.sort-by').get(0)
	})

	sortBy.on('name', function() {
		var byName = _.sortBy($('.located-item'), function(item) {
			return $(item).find('.name').html()
		})
		$('.located-items').empty()
		_.each(byName, function(item) {
			$('.located-items').append(item)
		})
	})

	sortBy.on('city', function() {
		var byCity = _.groupBy($('.located-item'), function(parish) {
			var city = $(parish).find('.city').html()
			return city
		})

		$('.located-items').empty()
		_.each(_.sortBy(_.keys(byCity)), function(city) {
			$('.located-items').append('<h2>' + city + '</h2>')
			_.each(_.sortBy(byCity[city], function(parish) {
				return $(parish).find('.name').html()
			}), function(parish) {
				$('.located-items').append(parish)
			})
		})
	})
	sortBy.on('pastor', function() {
		var byPastor = _.groupBy($('.located-item'), function(parish) {
			var pastor = $(parish).find('.clergy-name').text() || 'Unknown'

			return pastor
		})

		$('.located-items').empty()
		_.each(_.sortBy(_.keys(byPastor)), function(pastor) {
			$('.located-items').append('<h2>' + pastor + '</h2>')
			_.each(_.sortBy(byPastor[pastor], function(parish) {
				return $(parish).find('.name').html()
			}), function(parish) {
				$('.located-items').append(parish)
			})
		})
	})
}
