
var runWhenLoaded = require('./run-when-loaded')

var gallery = function(selector) {
	
	runWhenLoaded(selector, function() {
		if($(selector).size() > 0) {
			$(selector).shiner()
			var maxHeight = 0
			$(selector).each(function() {
				var h = $(this).height()
				if(h > maxHeight) {
					maxHeight = h
				}
			})
			// $(selector).each(function() {
			// 	var h = $(this).height()
			// 	$(this).css('top', (maxHeight - h) / 2)
			// })
			
			$(selector).closest('.gallery').append(
				'<div class="height-space" style="height: ' + maxHeight + 'px" >&nbsp;</div>')
		}
	})
}

module.exports = gallery