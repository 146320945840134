
var $ = require('jquery')

var allComplete = function(selector) {
	var result = true
	$(selector).each(function() {
		if(!this.complete || this.clientHeight == 0) {
			result = false
		}
	})
	
	return result
}
var runWhenLoaded = function(selector, callback) {
	var items = $(selector)
	if(allComplete(selector)) {
		callback()
	}
	else {
		items.on('load', function() {
			if(allComplete(selector)) {
				if(callback) {
					callback()
					callback = null
				}
			}
		})
	}
}

module.exports = runWhenLoaded