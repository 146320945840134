var $ = require('jquery')
var _ = require('underscore')

var library = function() {
	
}

library.prototype.getReservations = function() {
	var reservations = window.sessionStorage.getItem('reservations')
	if(!reservations) {
		reservations = []
	}
	else {
		reservations = JSON.parse(reservations)
	}
	return reservations
}

library.prototype.setReservations = function(reservations) {
	window.sessionStorage.setItem('reservations', JSON.stringify(reservations))
}


library.prototype.addItem = function(number, title) {
	var res = this.getReservations()
	res = _.filter(res, function(item) {
		return item.id != number
	})
	res.push({
		id: number,
		title: title
	})
	this.setReservations(res)
}

library.prototype.removeItem = function(number) {
	var res = this.getReservations()
	res = _.filter(res, function(item) {
		return item.id != number
	})
	this.setReservations(res)
}

library.prototype.removeAll = function() {	
	this.setReservations([])
}


library.prototype.drawBox = function() {
	$('.checkoutBox').remove()
	var reservations = this.getReservations();
	if(reservations.length > 0) {
		$('body').append(checkoutBox({ 
			reservations: reservations,
			ids: _.pluck(reservations, 'id').join(',')
		}))
	}
}

var sortMediaItems = function(items) {
	return _.sortBy(items, function(item) {
		var titleString = $(item).find('h2').text().toLowerCase()
		if($(item).css('display') == 'none') {
			titleString = 'zzz' + titleString
		}
		return titleString
	})
}

library.prototype.init = function() {
	var self = this
	if(this.getReservations().length > 0) {
		this.drawBox()
	}
	
	$('body').on('click', '.media-item button', function(evt){
		evt.preventDefault()
		var id = $(this).attr('data-item')
		var title = $(this).attr('data-title')
		self.addItem(id, title)
		self.drawBox();
	})
	
	$('.media-center-page form.search-form').submit(function(evt) {
		evt.preventDefault()
		var items = $('.media-item')
		var allItems = items
		items.each(function() {
			$(this).css('display', 'none')
		})
		
		var title = $.trim($('form input[name=title]').val())
		var titles = title.split(' ')
		_.each(titles, function(title) {
			title = title.toLowerCase()
			items = _.filter(items, function(item) {
				return $(item).find('h2').text().toLowerCase().indexOf(title) > -1
			})
		})

		var author = $.trim($('form input[name=author]').val())
		var authors = author.split(' ')
		_.each(authors, function(author) {
			author = author.toLowerCase()
			items = _.filter(items, function(item) {
				return $(item).find('.authors').text().toLowerCase().indexOf(author) > -1
			})
		})
		
		var keyword = $.trim($('form select[name=keywords]').val())
		var keywords = keyword.split(' ')
		_.each(keywords, function(keyword) {
			keyword = keyword.toLowerCase()
			items = _.filter(items, function(item) {
				return $(item).attr('data-keywords').toLowerCase().indexOf(keyword) > -1
			})
		})
		
		var publisher = $.trim($('form select[name=publisher]').val())
		if(publisher != '') {
			items = _.filter(items, function(item) {
				return $(item).find('.publisher').text().indexOf(publisher) > -1
			})
		}
		
		var category = $.trim($('form select[name=category]').val())
		if(category) {
			category = category.toLowerCase()
			items = _.filter(items, function(item) {
				return $(item).attr('data-categories').toLowerCase().indexOf(category) > -1
			})
		}
		
		var ageGroup = $.trim($('form select[name=ageGroup]').val())
		if(ageGroup) {
			ageGroup = ageGroup.toLowerCase()
			items = _.filter(items, function(item) {
				return $(item).attr('data-age-group').toLowerCase().indexOf(ageGroup) > -1
			})
		}
		
		_.each(items, function(item) {
			$(item).css('display', '')
		})
		
		allItems = sortMediaItems(allItems)
		$('.media-items').html('')
		$('.media-items').append(allItems)
	})
	
	$('body').on('click', '.checkoutBox .remove', function(evt) {
		evt.preventDefault()
		self.removeItem($(this).closest('.reservation').attr('data-id'))
		self.drawBox()
	})
	
	$('.checkout-complete-page').each(function() {
		self.removeAll()
		self.drawBox()
	})
	
	var allPublishers = []
	$('.media-item .publisher').each(function() {
		allPublishers.push($(this).text())
	})
	allPublishers = _.unique(allPublishers)
	allPublishers = _.sortBy(allPublishers, function(item) {
		return item.toLowerCase()
	})
	_.each(allPublishers, function(item) {
		$('form select[name=publisher]').append('<option>' + item + '</option>')
	})
}

module.exports = library