var $ = require('jquery')
var _ = require('underscore')

var items = []

var see = function(options, handler) {
	var props = {
		visibleBy: 0
	}
	if(typeof options != 'function') {
		_.extend(props, options)
	}
	else {
		handler = options
	}
	props.handler = handler
	props.result = this
	items.push(props)
	return this
}

var evalPositions = function() {
	var windowPosition = $(window).scrollTop()
	var windowBottom = windowPosition + $(window).height()
	_.each(items, function(watcher) {
		watcher.result.each(function() {
			var $this = $(this)
			if(!$this.data('watcher-done')) {
			var offset = $this.offset().top
				if(windowBottom > (offset + watcher.visibleBy)) {
					$this.data('watcher-done', 'invoked')
					if(window.requestAnimationFrame) {
						requestAnimationFrame(function() {
							watcher.handler($this)
						})
					}
					else {
						watcher.handler($this)
					}
				}
			}
		})
	})
}

$(window).on('scroll', function() {
	evalPositions()
})

see.evalPositions = evalPositions

$.fn.see = see

module.exports = $.fn.see