var Backbone = require('backbone')
var _ = require('underscore')

var PushButtonBar = Backbone.View.extend({
	
	tagName: "span",
	
	className: "push-buttons",
	
	events: {
		"click input": "change"
	},
	
	initialize: function() {
		_.bindAll(this, 'change')
	},
	
	render: function() {
		
	},
	
	change: function(evt) {
		this.trigger(event.target.value)
	}
	
	
	
});

module.exports = PushButtonBar