window.require = require
window.jQuery = window.$ = require('jquery')


var Tripartite = require('tripartite')

var templates = require('./pages.tmpl')
Tripartite.parseTemplateScript(templates)

var Shiner = require('shiner')
var gallery = require('./gallery')
var _ = require('underscore')
var see = require('./scroll-watch')
var pageEditor = require('page-editor')
require('./locatables')
var PicUpload = require('ei-pic-browser/pic-upload')
var Dialog = require('ei-dialog')
var ckeditorDrop = require('page-editor/node_modules/ei-pic-browser/ckeditor-drop')
window.UploadableImage = require('page-editor/uploadable-image')
require('events-for-sites')

var Library = require('./library')
var library = new Library()

var moment = require('moment')

$(function() {
	require('./make-map')
})


$(function() {
    $('.side-menu a').each(function() {
        if ($(this).attr('href') == window.location.pathname) {
            $(this).closest('li').addClass('current')
        }
    })

    gallery('.gallery img')

    $('form.search button').click(function(evt) {
        if (!$('form.search').hasClass('expanded')) {
            evt.preventDefault()
            $('form.search').addClass('expanded')
        } else {
            var form = $('form.search')
            var input = $('form.search input')
            var val = input.val()
            if (!val) {
                evt.preventDefault()
                $('form.search').removeClass('expanded')
            } else {
                var newLocation = form.attr('action') + val
                form.attr('action', newLocation)
                input.attr('name', '')
                evt.preventDefault()
                window.location = newLocation
            }
        }
    })

    $('.collapsable').click(function(evt) {
        if ($(evt.target).closest('.contains').length == 0) {
            if ($(this).hasClass('open')) {
                $(this).css('height', '')
            } else {
                $(this).css('height', $(this).find('.contains').height())
            }
            $(this).toggleClass('open')
        }
    })

    $('.intro-set').shiner({
        delay: 7000
    })
    $('.events-slider .slider-event').shiner()
    $('.chevron-left').click(function(evt) {
        $('.events-slider .slider-event').shiner('previous')
    })
    $('.chevron-right').click(function(evt) {
        $('.events-slider .slider-event').shiner('next')
    })


    $('.go-left').click(function(evt) {
        $('.intro-set').shiner('previous')
    })
    $('.go-right').click(function(evt) {
        $('.intro-set').shiner('next')
    })

    $('.tribar').click(function(evt) {
        evt.preventDefault()
        $('.menus').addClass('open')
        $('body').css('overflow', 'hidden')
    })

    $('.close-menu').click(function(evt) {
        evt.preventDefault()
        $('.menus').removeClass('open')
        $('body').css('overflow', 'auto')
    })

    if ($('.issuuembed').length > 0) {
        $('body').append('<script type="text/javascript" src="//e.issuu.com/embed.js" async="true"></script>')
    }

    library.init()
})

$(window).on('scroll', function() {
    if ($('.index-page').length > 0) {
        $('body').toggleClass('short-header', document.body.scrollTop > 130)

    } else {
        $('body').toggleClass('short-header', document.body.scrollTop > 0)
    }
})

process.nextTick(function() {
    $('.jumbo .slide').addClass('active')
})

$('.approach').see({
    visibleBy: 200
}, function(selection) {
    selection.addClass('visible')
})

see.evalPositions()


ckeditorDrop.prototype.insertDocumentLink = function(url, desc) {
    var edInstance = this.getEditorInstance()
    edInstance.insertHtml('<ul class="resources full"><li><a href="' + url + '" >' + desc + '</a></li></ul>')
}

pageEditor.imageLayouts = [{
        name: 'none',
        classes: ''
    },
    {
        name: 'full width',
        classes: 'full-width'
    },
    {
        name: 'on right',
        classes: 'on-right'
    }
]

pageEditor.extraPlugins = 'widget,staff-cell,big-quote,little-event-marker,color-link,captioned-video,pic-fade-link,courier-issue'

pageEditor.configure()

Tripartite.templates['fromNow'] = function(date) {
	return moment(date).fromNow()
}

var organizeComments = function(comments) {
	var commentsById = {}
	
	comments = _.sortBy(comments, function(comment) {
		return comment.created
	})
	
	_.each(comments, function(comment) {
		commentsById[comment.id] = comment
		comment.children = []
	})
	
	_.each(comments, function(comment) {
		if(comment.parentId) {
			commentsById[comment.parentId].children.push(comment)
		}
	})
	
	return _.filter(comments, function(comment) {
		return !comment.parentId
	})

}

var markEditableComments = function(userName) {
	$('.comment-info .author').each(function() {
		if($(this).text().trim() == userName) {
			$(this).closest('.comment-info').addClass('editable')
		}
	})
}


$('.comments').each(function() {
    var self = this
    $.get('/comments' + window.location.pathname, function(data) {
		$(self).find('.loading-message').remove()
		if(typeof data === 'array' || typeof data === 'object') {
	        _.each(organizeComments(data), function(item) {
	            $(self).find('.top-comments').append(Tripartite.templates['top-comment'](item))
	        })
			markEditableComments(window.userName)
		}
    })
})

$('.comments').on('submit', '.comment-form', function(evt) {
    evt.preventDefault()
    var data = $(this).serialize()
	var form = this
	
	var templ = Tripartite.templates['comment-info']
	var holder = $(this).closest('.comment')
	if(holder.length) {
		holder = holder.find('.child-comments')
	}
	else {
		holder = $(this).closest('.comments').find('.top-comments')
		templ = Tripartite.templates['top-comment']
	}
	
    data.page = window.location.pathname
    $.ajax({
        type: "POST",
        url: '/comments' + window.location.pathname,
        data: data,
        success: function(data) {
			holder.append(templ(data))
			form.reset()
			markEditableComments(window.userName)
		}
    })
})

$('.comments').on('click', '.delete', function(evt) {
	evt.preventDefault()
	
	if(confirm('Really delete?')) {
		
		var ci = $(this).closest('.comment-info')
		
		var id = ci.attr('data-id')
		
	    $.ajax({
	        type: "DELETE",
	        url: '/comments/' + id,
	        success: function(data) {
				if(ci.closest('.child-comments').length) {
					ci.remove()
				}
				else {
					ci.closest('.comment').remove()
				}
			}
	    })
	}
})

